'use client'

import {
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  DialogButton,
  DirectiveDescriptor,
  directivesPlugin,
  GenericDirectiveEditor,
  headingsPlugin,
  imagePlugin,
  insertDirective$,
  InsertTable,
  InsertThematicBreak,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  MDXEditorMethods,
  quotePlugin,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo,
  usePublisher
} from '@mdxeditor/editor'
import {TextareaHTMLAttributes, useEffect, useRef} from "react";
import '@mdxeditor/editor/style.css'
import {LeafDirective} from "mdast-util-directive";

interface Props extends Omit<TextareaHTMLAttributes<any>, 'onChange'> {
  value: string,
  onChange: (value: string) => void,
}

export function Editor({value, placeholder, ...props}: Props) {

  const ref = useRef<MDXEditorMethods>(null)

  useEffect(() => {
    if (ref && ref.current && value) {
      ref.current?.setMarkdown(value)
    }
  }, [value]);

  const handleChange = (value: string) => {
    if (props.onChange) {
      props.onChange(value)
    }
  }

  async function imageUploadHandler(image: File) {
    const formData = new FormData()
    formData.append('image', image)
    // send the file to your server and return
    // the URL of the uploaded image in the response
    const response = await fetch('/uploads/new', {
      method: 'POST',
      body: formData
    })
    const json = (await response.json()) as { url: string }
    return json.url
  }

  return <div className="border rounded">
    <MDXEditor ref={ref} markdown={value || ''} className={props.className} placeholder={placeholder}
               onChange={handleChange}
               plugins={[
                 directivesPlugin({directiveDescriptors: [YoutubeDirectiveDescriptor]}),

                 toolbarPlugin({
                   toolbarContents: () => (
                     <>
                       <UndoRedo/>
                       <BoldItalicUnderlineToggles/>
                       <BlockTypeSelect/>
                       <CreateLink/>
                       <InsertTable/>
                       <InsertThematicBreak/>
                       <ListsToggle/>
                       <YouTubeButton/>
                       <LineBreakButton/>
                     </>
                   )
                 }),
                 headingsPlugin(), listsPlugin(), linkPlugin(),
                 linkDialogPlugin(), quotePlugin(), markdownShortcutPlugin(),
                 thematicBreakPlugin(),
                 tablePlugin(),
                 imagePlugin({imageUploadHandler})]}/>
  </div>
}


export function ReadOnlyEditor({value, className}: { value: string, className?: string }) {

  const ref = useRef<MDXEditorMethods>(null)


  return <MDXEditor ref={ref} markdown={value} className={className} readOnly={true}
                    plugins={[
                      directivesPlugin({directiveDescriptors: [YoutubeDirectiveDescriptor]}),
                      headingsPlugin(), listsPlugin(), linkPlugin(),
                      linkDialogPlugin(), quotePlugin(), markdownShortcutPlugin(),
                      thematicBreakPlugin(),
                      tablePlugin()]}/>
}


const LineBreakButton = () => {
  // grab the insertDirective action (a.k.a. publisher) from the
  // state management system of the directivesPlugin
  const insertDirective = usePublisher(insertDirective$)

  return (
    <DialogButton
      tooltipTitle="Insert Youtube video"
      submitButtonTitle="Insert video"
      dialogInputPlaceholder="Paste the youtube video URL"
      buttonContent="YT"
      onSubmit={(url) => {
        console.log('event btn', url)
        // insertDirective({
        //   name: 'youtube',
        //   type: 'leafDirective',
        //   attributes: {id: 'videoId'},
        //   children: []
        // } as LeafDirective)
      }}
    />
  )
}


const YouTubeButton = () => {
  // grab the insertDirective action (a.k.a. publisher) from the
  // state management system of the directivesPlugin
  const insertDirective = usePublisher(insertDirective$)

  return (
    <DialogButton
      tooltipTitle="Insert Youtube video"
      submitButtonTitle="Insert video"
      dialogInputPlaceholder="Paste the youtube video URL"
      buttonContent="YT"
      onSubmit={(url) => {
        const videoId = new URL(url).searchParams.get('v')
        if (videoId) {
          insertDirective({
            name: 'youtube',
            type: 'leafDirective',
            attributes: {id: videoId},
            children: []
          } as LeafDirective)
        } else {
          alert('Invalid YouTube URL')
        }
      }}
    />
  )
}

const YoutubeDirectiveDescriptor: DirectiveDescriptor = {
  name: 'youtube',
  testNode(node) {
    return node.name === 'youtube'
  },
  // set some attribute names to have the editor display a property editor popup.
  attributes: [],
  // used by the generic editor to determine whether or not to render a nested editor.
  hasChildren: false,
  Editor: (props) => {
    return (
      <iframe width="800" height="350"
              src={'https://www.youtube.com/embed/' + props.mdastNode.attributes!.id}>
      </iframe>
    )
  }
}

const CalloutDirectiveDescriptor: DirectiveDescriptor = {
  name: 'callout',
  testNode(node) {
    return node.name === 'callout'
  },
  // set some attribute names to have the editor display a property editor popup.
  attributes: [],
  // used by the generic editor to determine whether or not to render a nested editor.
  hasChildren: true,
  Editor: GenericDirectiveEditor

}