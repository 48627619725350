'use client'

import {Controller, UseFormReturn} from "react-hook-form";
import * as React from "react";
import {PropsWithChildren, SyntheticEvent, useEffect, useState} from "react";
import classNames from "classnames";
import {InputText, InputTextProps} from "primereact/inputtext";
import {SButton} from "../atoms/button";
import {ButtonProps} from "primereact/button";
import {InputTextarea, InputTextareaProps} from "primereact/inputtextarea";
import {Dropdown, DropdownProps} from "primereact/dropdown";
import {MultiSelect, MultiSelectProps} from "primereact/multiselect";
import {AutoComplete, AutoCompleteProps} from "primereact/autocomplete";
import {Checkbox, CheckboxProps} from "primereact/checkbox";
import {ExpandableList} from "./list";
import {PasswordProps} from "primereact/password";
import {Calendar, CalendarProps} from "primereact/calendar";
import {addLocale} from "primereact/api";
import {useGetCitiesMutation} from "@/lib/client/slices/world";
import {Rating} from "../atoms/rating";
import {Editor} from "@/components/molecules/editor";
import {InputNumber} from "primereact/inputnumber";

interface ControlledFormProps extends PropsWithChildren {
  useForm: UseFormReturn<any, any>
  onSubmit: (data: any) => void
}

export const ControlledForm = ({useForm, onSubmit, children}: ControlledFormProps) => {
  return <form className="w-full" onSubmit={useForm.handleSubmit(data => onSubmit({data}))}>
    {children}
  </form>
}


interface ControlledSubmitProps extends PropsWithChildren<ButtonProps> {
  useForm: UseFormReturn<any, any>
}

export const ControlledButtonSubmit = ({useForm, onSubmit, ...rest}: ControlledSubmitProps) => {
  return <SButton {...rest} type={"submit"}/>
}


function getLabel(label?: string, required?: boolean, help?: string) {
  return required === true && label ? <>{label} <span className="text-gray-400 text-sm">(obligatoire)</span></> : label;
}

function getRules(label?: string, required?: boolean) {
  return {required: required ? 'champs requis' : undefined};
}

function getFormErrorMessage(errors: any, name: string) {
  return errors[name] && <span className="text-red-600 text-sm">{errors ? errors[name]!.message : 'Error'}</span>
}

type ControlledProps<T> = PropsWithChildren<T> & {
  label?: string;
  help?: string;
  name: string
  useForm: UseFormReturn<any, any>
  required?: boolean
}

const Field = (props: any) => <div {...props} className={classNames(props.className, "field m-2 pr-4 flex flex-col")}/>
const Label = (props: any) => <label htmlFor={props.name}
                                     className={classNames('text-sm', {'text-red-600 ': props.errors[props.name]})}>{getLabel(props.label, props.required, props.help)}</label>

export const ControlledInputText = ({
                                      label,
                                      name,
                                      useForm,
                                      required,
                                      className,
                                      help,
                                      ...rest
                                    }: ControlledProps<InputTextProps>) => {
  const {formState: {errors}, control} = useForm

  return <Field className={className}>
    <Label name={name} errors={errors} label={label} required={required} help={help}/>
    <Controller name={name} control={control} rules={getRules(label, required)}
                render={({field, fieldState}) => (
                  <InputText id={field.name} {...field} {...rest}
                             className={classNames({'p-invalid': fieldState.invalid})} pt={{
                    //@ts-ignore
                    root: 'text-base px-2 py-2 rounded border-gray-200'
                  }}/>)}/>
    {getFormErrorMessage(errors, name)}
  </Field>
}

export const ControlledInputCurrency = ({
                                          label,
                                          name,
                                          useForm,
                                          required,
                                          className,
                                          help,
                                          ...rest
                                        }: ControlledProps<InputTextProps>) => {
  const {formState: {errors}, control} = useForm

  return <Field className={className}>
    <Label name={name} errors={errors} label={label} required={required} help={help}/>
    <Controller name={name} control={control} rules={getRules(label, required)}
                render={({field, fieldState}) => (
                  <InputNumber id={field.name} {...field} {...rest} onChange={e => console.log('currency on change', e)}
                               onValueChange={e => field.onChange(e.value)}
                               mode="currency" currency="EUR" locale="fr-FR"
                               className={classNames({'p-invalid': fieldState.invalid})} pt={{
                    //@ts-ignore
                    root: 'text-base px-2 py-2 rounded border-gray-200'
                  }}/>)}/>
    {getFormErrorMessage(errors, name)}
  </Field>
}

export const ControlledInputPassword = ({
                                          label,
                                          name,
                                          useForm,
                                          required,
                                          help,
                                          ...rest
                                        }: ControlledProps<PasswordProps>) => {
  const {formState: {errors}, control} = useForm

  return <Field>
    <div className="label flex flex-col">
      <Label name={name} errors={errors} label={label} required={required} help={help}/>
      <Controller name={name} control={control} rules={getRules(label, required)}
                  render={({field, fieldState}) => (<input type="password" id={field.name} {...field}
                                                           className={classNames('border border-gray-300 w-full rounded-lg p-1 block', {'p-invalid': fieldState.invalid})}/>)}/>
    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}

export const ControlledInputTextarea = ({
                                          label,
                                          name,
                                          useForm,
                                          required,
                                          help,
                                          ...rest
                                        }: ControlledProps<InputTextareaProps>) => {
  const {formState: {errors}, control} = useForm
  return <Field>
    <div className="label">
      <Label name={name} errors={errors} label={label} required={required} help={help}/>
      <Controller name={name}
                  control={control} rules={getRules(label, required)}
                  render={({field, fieldState}) => (<InputTextarea id={field.name} {...field} {...rest}
                                                                   className={classNames('bg-gray-100 border-gray-200  w-full rounded p-2 mb-2  block', {'p-invalid': fieldState.invalid})}/>)}/>
    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}


export const ControlledEditor = ({
                                   label,
                                   name,
                                   useForm,
                                   required,
                                   help,
                                   ...rest
                                 }: ControlledProps<InputTextareaProps>) => {
  const {formState: {errors}, control} = useForm
  return <Field>
    <div className="label">
      <Label name={name} errors={errors} label={label} required={required} help={help}/>
      <Controller name={name}
                  control={control} rules={getRules(label, required)}
                  render={({field, fieldState}) => (<Editor
                    id={field.name} {...field} {...rest}
                    onChange={(e: string) => field.onChange(e)}
                    className={classNames('border-gray-200  w-full rounded p-2 mb-2  block', {'p-invalid': fieldState.invalid})}/>)}/>
    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}


export const ControlledDropdown = ({
                                     label,
                                     name,
                                     useForm,
                                     required,
                                     className,
                                     help,
                                     ...rest
                                   }: ControlledProps<DropdownProps>) => {
  const {formState: {errors}, control} = useForm

  return <Field>
    <div className={classNames(className, "label flex flex-col")}>
      <Label name={name} errors={errors} label={label} required={required} help={help}/>
      <Controller name={name} control={control} rules={getRules(label, required)}
                  render={({field, fieldState}) => (
                    <Dropdown id={field.name} optionLabel={"label"} {...field} {...rest} placeholder={label}
                              className={classNames({'p-invalid': fieldState.invalid})} pt={{
                      //@ts-ignore
                      root: {
                        input: 'text-xs px-2 py-2 rounded border-gray-200',
                        header: {
                          className: 'p-1'
                        }
                      }
                    }}/>)}/>
    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}


export const ControlledSelectDropdown = ({
                                           label,
                                           name,
                                           useForm,
                                           required,
                                           className,
                                           help,
                                           ...rest
                                         }: ControlledProps<MultiSelectProps>) => {
  const {formState: {errors}, control} = useForm

  return <Field className={className}>
    <div className={"label flex flex-col"}>
      <Label name={name} errors={errors} label={label} required={required} help={help}/>
      <Controller name={name} control={control} rules={getRules(label, required)}
                  render={({field, fieldState}) => <MultiSelect id={field.name}
                                                                className={classNames({'p-invalid': fieldState.invalid})}
                                                                name={field.name} value={field.value} {...rest}
                                                                onChange={(e: any) => field.onChange(e.value)}
                                                                optionLabel="label" placeholder={label}/>}
      />
    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}


export const ControlledAutocompleteDropdown = ({
                                                 label,
                                                 name,
                                                 useForm,
                                                 required,
                                                 className,
                                                 help,
                                                 ...rest
                                               }: ControlledProps<AutoCompleteProps>) => {
  const {formState: {errors}, control} = useForm

  return <Field>
    <div className={classNames(className, "label flex flex-col")}>
      <Label name={name} errors={errors} label={label} required={required} help={help}/>
      <Controller name={name} control={control} rules={getRules(label, required)} render={({field, fieldState}) => (
        <AutoComplete inputId={field.name}
                      value={field.value}
                      onChange={field.onChange}
                      inputRef={field.ref}
                      className={classNames({'p-invalid': fieldState.error})}
                      {...rest} />)}/>
    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}


export const ControlledCalendar = ({
                                     label,
                                     name,
                                     useForm,
                                     required,
                                     className,
                                     help,
                                     ...rest
                                   }: ControlledProps<CalendarProps>) => {
  const {formState: {errors}, control} = useForm

  addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  });

  addLocale('fr', {
    firstDayOfWeek: 1,
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim', 'lun', 'mar', 'mer', 'jeu', 'ven', 'sam'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'aout', 'septembre', 'octobre', 'novembre', 'décembre'],
    monthNamesShort: ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aou', 'sep', 'oct', 'nov', 'déc'],
    today: 'Aujourd\'hui',
    clear: 'Effacer'
  });

  return <Field>
    <div className={classNames(className, "label flex flex-col")}>
      <Label name={name} errors={errors} label={label} required={required} help={help}/>
      <Controller name={name} control={control} rules={getRules(label, required)} render={({field, fieldState}) => (
        <Calendar
          placeholder={label}
          inputRef={field.ref}
          value={field.value}
          dateFormat="dd/mm/yy"
          locale="fr"
          showIcon
          onChange={(e: any) => field.onChange(e.value)}
          className={classNames({'p-invalid': fieldState.error})}
          {...rest} />)}/>
    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}


export const ControlledCheckbox = ({
                                     label,
                                     name,
                                     useForm,
                                     required,
                                     help,
                                     ...rest
                                   }: ControlledProps<Omit<CheckboxProps, "checked">>) => {
  const {formState: {errors}, control} = useForm

  return <Field>
    <div className="label">
      <Controller name={name} control={control} rules={getRules(label, required)}
                  render={({field, fieldState}) => (<span>
                    <Checkbox id={field.name} name={field.name} checked={field.value}
                              onChange={e => field.onChange(e.checked)} {...rest}
                              className={classNames({'p-invalid': fieldState.invalid})}/>
                    <span className="pl-1">{rest.children}</span>
                  </span>)}/>
      <Label name={name} errors={errors} label={label} required={required} help={help}/>

    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}

interface InputRangeProps {

  renderValue?: (v: string | number) => string
}

export const ControlledRange = ({
                                  label,
                                  name,
                                  useForm,
                                  className,
                                  required,
                                  help,
                                  ...rest
                                }: ControlledProps<React.AllHTMLAttributes<any> & InputRangeProps>) => {
  const {formState: {errors}, control} = useForm

  return <Field>
    <div className={classNames(className, "label flex flex-col")}>
      <Label name={name} errors={errors} label={label} required={required} help={help}/>
      <Controller name={name} control={control} rules={getRules(label, required)}
                  render={({field, fieldState}) => (<div>
                    <div className="flex justify-between w-full">
                      {rest.min && <div>{rest.renderValue ? rest.renderValue(rest.min) : 0}</div>}
                      <div>{rest.renderValue ? rest.renderValue(field.value) : 0}</div>
                      {rest.max && <div>{rest.renderValue ? rest.renderValue(rest.max) : 0}</div>}
                    </div>
                    <input type="range" {...field} max={rest.max} min={rest.min} step={rest.step}
                           className="h-2 w-full cursor-ew-resize appearance-none rounded-full bg-gray-200 disabled:cursor-not-allowed"/>
                  </div>)}/>
    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}

export const ControlledRating = ({
                                   label,
                                   name,
                                   useForm,
                                   required,
                                   help,
                                   ...rest
                                 }: ControlledProps<Omit<CheckboxProps, "checked">>) => {
  const {formState: {errors}, control} = useForm

  return <Field>
    <div className="label">
      <Controller name={name} control={control} rules={getRules(label, required)}
                  render={({field, fieldState}) => (<Rating id={field.name} {...field} {...rest}
                                                            className={classNames({'p-invalid': fieldState.invalid})}/>)}/>
      <Label name={name} errors={errors} label={label} required={required} help={help}/>

    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}

export const ControlledExpandableList = ({
                                           label,
                                           name,
                                           useForm,
                                           required,
                                           max,
                                           children,
                                           help
                                         }: ControlledProps<PropsWithChildren<{ max: number }>>) => {
  const {formState: {errors}, control} = useForm

  return <Field>
    <div className="label">
      <Label name={name} errors={errors} label={label} required={required} help={help}/>
      <ExpandableList max={max}>
        {children}
      </ExpandableList>
    </div>
    {getFormErrorMessage(errors, name)}
  </Field>
}

export const ControlledCitySelector = ({label, name, useForm, ...rest}: ControlledProps<AutoCompleteProps>) => {
  const [filteredCities, setFilteredCities] = useState<{ label: string, value: string | undefined }[]>([]);
  const [getCities, status] = useGetCitiesMutation()
  const [inputValue, setInputValue] = useState('');
  const delay = 1500; // 300 ms de délai
  let timeoutId: any;
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []); // Assurez-vous que cela ne s'exécute qu'une seule fois lors du montage


  const searchCities = (e: { originalEvent: SyntheticEvent, query: string }) => {

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      // Appeler la fonction après le délai
      // Remplacez la fonction suivante par celle que vous souhaitez appeler
      if (e.query.length >= 3) {
        getCities({filter: e.query})
          .then((r: any) => {
            if (r.data && r.data.length > 0) {
              setFilteredCities(r.data.map((c: any) => ({label: `${c.name} (${c.postal_code})`, value: c})))
            } else {
              setFilteredCities([{label: '', value: undefined}])
            }
          })
      }
    }, delay);


  }

  const itemTemplate = (item: { label: string, value: any }) => {
    if (!item.value) {
      return (
        <div className="text-sm text-center">
          <div>Aucun résultat.</div>
          <div>Essayez une autre orthographe ou une ville proche</div>
        </div>
      )
    }
    return (
      <div className="text-sm">
        <div>{item.value.name} ({item.value.postal_code})</div>
      </div>
    );
  }

  return <div className={"flex flex-row items-center"}>
    <ControlledAutocompleteDropdown useForm={useForm}
                                    placeholder="Saisissez une ville"
                                    label={"Ville"} name={"city"}
                                    suggestions={filteredCities}
                                    field="label"
                                    completeMethod={searchCities}
                                    itemTemplate={itemTemplate}{...rest}/>
  </div>
}
