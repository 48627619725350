'use client'

import {DetailedHTMLProps, InputHTMLAttributes, useState} from "react";
import styled, {css} from "styled-components";

const StyledRating = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
`

const StyledStar = styled.div<{ highlight: boolean, size?: string }>`
    font-size: ${props => props.size == 'sm' ? '15px' : '35px'};

    ${props => props.highlight && css`
    color: goldenrod;
  `}
    &:hover:not(.disabled) {
        cursor: pointer;
        color: darkgoldenrod;
    }
`

const StyledValue = styled.div<{ size?: string }>`
  font-size: ${props => props.size == 'sm' ? '15px' : '25px'};
  color: goldenrod;
  padding: 2px 10px;
`

interface Props {
  value?: number
  max?: number

  size?: 'sm'
}

const star = '★'


export const Rating = ({
                         value,
                         max,
                         min,
                         size,
                         ...rest
                       }: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & Props & any) => {
  const [stars, setStars] = useState<any[]>([])

  const handleChange = (value: number) => {
    if (!rest.disabled) {
      rest.onChange(value)
    }
  }
  return <StyledRating>
    {[...Array(max)].map(
      (item, i) => <StyledStar size={size} key={'star-' + i} className={rest.disabled ? 'disabled' : ''}
                               highlight={value < min || value > i} onClick={() => handleChange(i + 1)}>★</StyledStar>)}
    <StyledValue size={size}>{value ?? '-'}</StyledValue>
    <input type="number" {...rest} value={value} style={{visibility: 'hidden'}}/>
  </StyledRating>
}
