'use client'

import {Children, PropsWithChildren, useEffect, useState} from "react";
import styled from "styled-components";
import Link from "next/link";

interface Props {
  title: string

  href: string

  description: any
}

export const ListItem = (props: Props) => {
  return <div key={props.title} className={"item"}>
    <Link href={props.href} title={props.title}><h3 className={"title"}>➤ {props.title}</h3></Link>
    <div className={"placeholder"}>{props.description}</div>
  </div>
}


const StyledExpandableList = styled.div`
`
const StyledMore = styled.div`
  color: ${props => props.theme.primary.color};
  padding: 0 ${props => props.theme.padding}px ${props => props.theme.padding}px ${props => props.theme.padding}px;
  display:block;
  position: relative;
  top: -${props => props.theme.padding}px;
  cursor: pointer;
  margin-bottom: ${props => props.theme.padding}px;
  &:hover{
    color: ${props => props.theme.secondary.color};
  }
`

export const ExpandableList = ({max, children}: PropsWithChildren<{ max: number }>) => {

  const [visibleChildren, setVisibleChildren] = useState<any[]>([]);
  const [isExpanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    if (isExpanded) {
      setVisibleChildren(Children.toArray(children))
    } else {
      setVisibleChildren(Children.toArray(children).splice(0, max))
    }
  }, [max, isExpanded])

  return <div className={"pt-2 pb-2"}>
    <StyledExpandableList>
      {visibleChildren}
    </StyledExpandableList>
    {max != Children.toArray(children).length && isExpanded &&
        <StyledMore onClick={_ => setExpanded(false)}>- Réduire</StyledMore>}
    {max != Children.toArray(children).length && !isExpanded &&
        <StyledMore onClick={_ => setExpanded(true)}>+ Agrandir</StyledMore>}
  </div>
}


const StyledBulletList = styled.div`
  color: green;
`
export const BulletList = ({items}: { items: { label: string, href?: string }[] }) => {
  return <StyledBulletList>
    <ul>
      {items.map(i => <li key={i.label}>{i.label}</li>)}
    </ul>
  </StyledBulletList>
}